/**
 * Clickable blocks - entire block will be clickable
 * Styles are in _helpers.scss under .block--linked
 *
 * Usage:
 * {{#link}} data-clickable="{{link_path}}"{{/link}}
 * {{#url}} data-clickable="{{href}}"{{/url}}
 */

(function($) {
  Drupal.behaviors.clickable = {
    attach: function(context) {
      $('[data-clickable!=""][data-clickable]', context).once('clickable').addClass('block--linked').on('click', function() {
        if (window.location.hash) {
          history.replaceState('', '', $(this).data('clickable')); // fix for hitchhiking hashes
        }
        window.location.href = $(this).data('clickable');
      });
    }
  };
})(jQuery);
